import React, { useState } from "react"

import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'

import writeImg from '../images/general/logos/go-write-logo-rev.svg';
import readImg from '../images/general/logos/go-read-logo-rev.svg';

import boomWriteImg from '../images/home/boomwriter-img.png';
import boomReadImg from '../images/home/boomreader-img.png';
import { ExternalLink } from 'react-feather';
import logoChange from '../images/home/logo-change.png';

const Home = () => {

  return (
    <>

      <Helmet>
        <title>GoApps&trade; Has Changed Names</title>
        <meta name="description" content="We've changed the names of our apps GoWrite is now BoomWriter &amp; GoRead is now BoomReader" />
      </Helmet>

      <Layout>
        <div className="logoChange">
          <Container>
            <div className="logoChange_inner">
              <p>We've changed the names of our apps...</p>
              <img src={logoChange} alt='logo change' />
              <p>GoWrite is now BoomWriter &amp; GoRead is now BoomReader</p>
            </div>
          </Container>
        </div>

        <Container>
          <Row className="text-center flex-column flex-md-row mb-md-1 pb-4 mt-4 py-3">
            <Col xs={{}} sm={10} md={6} className="d-flex mx-auto panel-first">
              <a href="https://boomreader.co.uk" className="panel panel-link">
                <img className='logoAppBoomReader logoApp' src={boomReadImg} alt="BoomReader" />
                <h2>BoomReader</h2>
                <p className="smallText">Formerly GoRead</p>
                <img className="panel-logo"  alt="GoRead" src={readImg} />

                <p>A digital reading record for all of your pupils. Your children will build up a full reading record across their entire journey through school.</p>
                <p className="align-self-center inline-link fontWeight-bold d-inline-flex align-items-center">boomreader.co.uk <ExternalLink className="icon ml-half" size={16} /></p>
              </a>
            </Col>
            <Col xs={{}} sm={10} md={6} className="d-flex mx-auto panel-last">
              <a href="https://boomwriter.co.uk" className="panel panel-link">
                <img className='logoAppBoomWriter logoApp' src={boomWriteImg} alt="BoomWriter UK" />
                <h2>BoomWriter UK</h2>
                <p className="smallText">Formerly GoWrite</p>
                <img className="panel-logo" alt="GoWrite" src={writeImg} />

                <p>Create a publishing powerhouse in your school. At last pupils have both purpose and an audience for their writing.</p>
                <p className="align-self-center inline-link fontWeight-bold d-inline-flex align-items-center">boomwriter.co.uk <ExternalLink className="icon ml-half" size={16} /></p>
              </a>
            </Col>
          </Row>

        </Container>
        
      </Layout>
    </>
  )
}

export default Home
